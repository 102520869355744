//@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+KR');
//@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic+Coding');
//@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic');
//@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR');
//@import url('https://fonts.googleapis.com/css?family=Jua');

@font-face {
  font-family: 'IBM Plex Sans KR';
  font-weight: normal;
  //font-display: swap;
  src: url('./assets/fonts/IBMPlexSansKR-Regular.ttf') format('truetype');
}

*:not(.pi) {
  font-family: 'IBM Plex Sans KR', var(--font-family) !important;
  //font-family: "Nanum Gothic Coding", Roboto, serif;
  //font-family: "Nanum Gothic", Roboto, serif;
  //font-family: "Noto Sans KR", Roboto, serif;
  //font-family: "BM Jua", Roboto, serif;
}

:root {
  --red-50: #fff5f5;
  --red-100: #ffd1ce;
  --red-200: #ffada7;
  --red-300: #ff8980;
  --red-400: #ff6459;
  --red-500: #ff4032;
  --red-600: #d9362b;
  --red-700: #b32d23;
  --red-800: #8c231c;
  --red-900: #661a14;
}

body {
  color: black;
}

.p-inputgroup-addon {
  color: rgba(0, 0, 0, 0.9);
}

.p-panel .p-panel-content {
  color: black;
}

.p-dialog .p-dialog-content {
  color: black;
}

.pcr > * {
  .p-datatable .p-datatable-thead > tr > th {
    color: #ffffff;
    background: #0d3c61;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  color: black;
}

.p-datatable .p-datatable-tbody > tr {
  color: black;
}

/* dropdown ovverride */
.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
}

/* radio button override */

.p-radiobutton .p-radiobutton-box.p-highlight {
  position: relative;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  width: 70%;
  height: 70%;
  transition: transform 0.5s;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform-origin: center;
  transform: translate(-50%, -50%);
}

/* browser auto complete background reset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label {
  cursor: pointer;
}

/* browser auto complete background reset */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* radio button override */

.p-radiobutton .p-radiobutton-box.p-highlight {
  position: relative;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  width: 70%;
  height: 70%;
  transition: transform 0.5s;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  // transform-origin: center;
  transform: translate(-50%, -50%);
}

.pm-stamp img {
  width: 60px;
  height: 60px;
}

.cm-stamp img {
  width: 37px;
  height: 37px;
}

.cm-stamp .p-image-preview-indicator {
  height: 37px;
}

.cm-no-border {
  border: 0 !important;
}

.cm-dialog-content {
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
}

.cm-inner-panel {
  .p-panel-header {
    padding: 0.6rem;
  }
}

.cm-pcform-table {
  width: 100%;
  min-width: 1808px;
  table-layout: fixed;
  border: 1px solid var(--gray-400);

  .left {
    width: 900px;

    table {
      table-layout: fixed;

      th {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }
    }
  }

  .splitter {
    width: 8px;
    //background-color: var(--gray-400);
  }

  .right {
    width: 900px;

    table {
      table-layout: fixed;

      th {
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 2px;
      }
    }
  }

  //tbody {
  //  th {
  //    width: 100px;
  //    background-color: #e9ecef;
  //    border: 1px solid #ced4da;
  //    border-radius: 2px;
  //  }
  //
  //  td {
  //    width: 200px;
  //    padding: 0 4px;
  //  }
  //}
}

.p-fileupload {
  .p-fileupload-content {
    padding: 0.5rem;
  }
}

.p-image-toolbar {
  z-index: inherit;
}

.p-panel {
  .p-panel-title {
    width: 100% !important;
  }

  .p-panel-content {
    padding: 1rem;
  }
}

.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

// @page {
//   size: A4 portrait;
//   margin: 0 !important;
//   padding: 0 !important;
// }

// @media print {
//   html,
//   body {
//     width: 210mm;
//     height: 297mm;
//     background: #ffffff;
//     margin: 0 !important;
//     padding: 0 !important;
//   }

//   .pcr {
//     .paper {
//       width: 210mm;
//       height: 297mm;
//       min-height: 297mm;
//       border: initial;
//       border-radius: initial;
//       box-shadow: initial;
//       background: initial;
//       page-break-after: initial;
//     }
//   }
// }

@media print {
  // @page {
  //   size: A4 landscape;
  //   margin: 0 !important;
  //   padding: 0 !important;
  // }

  // html,
  // body {
  //   width: 210mm;
  //   height: 297mm;
  //   background: #ffffff;
  //   margin: 0 !important;
  //   padding: 0 !important;
  //   color: black;
  // }

  .pcr {
    .paper_container {
      width: 297mm;
      height: 210mm;
      min-height: 210mm;
      border: none;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      background: #ffffff;
      padding: 0;
      border-spacing: 0;
      // page-break-after: initial;
      overflow: hidden;
      // background-color: rgba(0, 0, 0, 0.2);
      display: flex;

      .paper_wrapper {
        width: 50%;
        // background-color: rgba(0, 0, 255, 0.2);

        .paper {
          box-shadow: none;
          padding: 0;
          margin: 0;
          transform: scale(0.707);
          transform-origin: 0 0;
          display: inline-block;
          // page-break-after: avoid;
          // background-color: rgba(255, 0, 0, 0.2);
        }
      }
    }
  }

  .claimPaper {
    margin: 0 auto !important;
    padding: 0 !important;
    background: #ffffff;

    .inner {
      box-shadow: none !important;
    }

    .imageBox {
      border: 1px solid #b7b7b7;
      height: auto;
      overflow: hidden;
      min-width: 50%;
      break-inside: auto !important;
      page-break-inside: auto !important;
      page-break-after: auto !important;
    }
  }
}

.pcr_wrapper {
  min-width: 210mm;
}

.pcr {
  padding: 0 !important;
  margin: 0 !important;

  .paper {
    width: 210mm;
    height: 297mm;
    min-height: 297mm;
    padding: 1rem 1.8rem !important;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    position: relative !important;
    border-spacing: 0;

    .content {
      border-width: 2px !important;
      border-style: solid !important;
      border-spacing: 0;
      padding: 0;

      .cbi {
        width: 12px;
        height: 12px;
      }

      .cbib {
        width: 16px;
        height: 16px;
      }

      table {
        padding: 0 !important;
        border-spacing: 0;
        table-layout: fixed;
        letter-spacing: -1px;

        td {
          padding: 0 3px;
        }

        .border-top-dotted {
          border-top-width: 1px !important;
          border-top-style: dotted !important;
        }

        .border-right-dotted {
          border-right-width: 1px !important;
          border-right-style: dotted !important;
        }

        .border-bottom-dotted {
          border-bottom-width: 1px !important;
          border-bottom-style: dotted !important;
        }

        .border-left-dotted {
          border-left-width: 1px !important;
          border-left-style: dotted !important;
        }

        .border-top-solid {
          border-top-width: 1px !important;
          border-top-style: solid !important;
        }

        .border-right-solid {
          border-right-width: 1px !important;
          border-right-style: solid !important;
        }

        .border-bottom-solid {
          border-bottom-width: 1px !important;
          border-bottom-style: solid !important;
        }

        .border-left-solid {
          border-left-width: 1px !important;
          border-left-style: solid !important;
        }
      }

      .sub-title {
        border-bottom-width: 3px !important;
        border-bottom-style: double !important;
        background-color: var(--gray-300) !important;
      }

      .sub-content {
        border-bottom-width: 2px !important;
        border-bottom-style: solid !important;
        border-bottom-color: var(--surface-500) !important;
        vertical-align: top;
      }
    }
  }
}

.p-galleria-item-next-icon,
.p-galleria-item-prev-icon {
  color: black;
}

.p-galleria-item-nav {
  top: 300px;
  z-index: 1;
}

.p-galleria-thumbnail-container {
  z-index: 1;
}

.p-galleria-content {
  display: flex;
  justify-content: center;
}

#claim-dialog .p-card-title,
.p-panel-title {
  font-size: 1.1rem;
  font-weight: 800;
}

#claim-dialog .p-card-title {
  color: var(--primary-color);
}

#claim-dialog .p-card-content {
  padding-bottom: 0px;
}

#claim-dialog,
#note .p-panel-content {
  height: 90vh;
}

.dashboard-h {
  height: calc(100vh - 9rem);
}

.input-border-primary {
  background: var(--primary-color);
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 0.5rem 0.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
}

#pcr .shadow-2 {
  box-shadow: none !important;
  border: 1px solid var(--surface-200);
  border-radius: 4px;
}

.pcr-short-wrap {
  display: flex;
  justify-content: center;
  padding: 10px;
  color: black;

  .pcr-short {
    width: 340px;
    height: 100%;

    .border-bottom-0 {
      border-bottom: 0;
    }

    .border-1,
    .border-bottom-1,
    .border-left-1 {
      border-color: #dadada;
    }
  }
}

.p-togglebutton.p-button.p-highlight {
  background: #44b66e;
  border-color: #399e5e;

  &:hover {
    background: #44b66e;
    border-color: #399e5e;
  }
}

.claimPaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 3rem;
  margin: 0 auto !important;
  gap: 10px;

  .inner {
    width: 210mm;
    padding: 1.8rem !important;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    position: relative !important;
    border-spacing: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    td,
    tr {
      border: 1px solid #aec1f5;
      text-align: center;
      padding: 10px;
    }

    .header {
      background-color: #1a7bce;
      border: 1px solid #1a7bce;
      color: white;
      font-weight: 700;
      font-size: 1.2rem;
      padding: 1rem;
    }

    .subject {
      background-color: #f3f7fb;
      font-weight: 700;
    }

    .content {
      min-width: 20mm;
    }
  }

  .section_title {
    background-color: #b3d1fe;
    border: 1px solid #a6caff;
    margin: -1px;
    margin-top: 20px;
    color: black;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 1rem;
    text-align: center;
  }

  // 이미지 박스를 감싸는 div
  .imageWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1rem;
    gap: 1rem;

    &.mini {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // 코드별 이미지 박스
  .imageBox {
    border: 1px solid #b7b7b7;
    height: auto;
    overflow: hidden;
    min-width: 50%;
    break-inside: avoid;
    /* 중간에 잘리지 않도록 설정 */
    page-break-inside: avoid;
    /* 페이지 내부에서 잘리지 않도록 설정 */
    page-break-after: always;
  }

  // 아이템이 하나일 때 절반 크기만 사용
  .half {
    grid-column: span 1;
  }

  .full {
    grid-column: span 2;
  }

  .partner-imageBox {
    grid-column: span 2;
    border: 1px solid #dadada;
    margin: -1px;
  }

  .partner-wide {
    grid-column: span 4;
  }

  // 서류 이름
  .section_sub_title {
    background-color: #eaebec;
    border-bottom: 1px solid #b7b7b7;
    color: black;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 0.5rem;
    text-align: center;
  }

  // 한 장이 아닐 경우 그리드 사용
  .twice-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .wide-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .image-container {
    img {
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .empty-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    justify-content: center;

    p {
      font-size: 1rem;
      text-align: center;
      font-weight: 700;
    }
  }
}
